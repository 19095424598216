import React from 'react'
import { graphql } from 'gatsby'
import { Hero, DownloadWithBackground, ContactPerson } from 'src/sections'
import WrappedImage from 'src/components/WrappedImage'
import Seo from 'src/components/Seo'
import IntroWithCard from 'src/sections/IntroWithCard'
import Testimonials from 'src/sections/Testimonials'

const BestPracticeSingle = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const practiceSingle = page.atspBestPracticeSingle
  const challenge = practiceSingle.bestPracticeSingleChallenge
  const approach = practiceSingle.bestPracticeSingleApproach
  const downloads = practiceSingle.bestPracticeDownload
  const downloadsIcon =
    (downloads.downloadIcon && downloads.downloadIcon.localFile.publicURL) ||
    undefined
  const downloadRepeater = downloads.downloadCard.downloadRepeater || undefined
  const solution = practiceSingle.bestPracticeSingleSolution
  const contactPerson = practiceSingle.bestPracticeSingleContact
  const socialSlug =
    page.language.locale.replace('_', '-').toLowerCase() +
    '/best-practices/' +
    page.slug

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      {hero && hero.heroImage && hero.heroImage.localFile && (
        <Hero
          hasIntroCard
          imageData={hero.heroImage.localFile}
          heading={hero.heroHeading}
          label={practiceSingle.heroLabel}
        />
      )}
      <IntroWithCard
        heading={challenge.heading}
        introText={challenge.text}
        introCard={challenge.introCard}
        socialSlug={socialSlug}
        socialTitle={page.title}
      />
      <WrappedImage
        heading={approach.heading}
        text={approach.text}
        image={approach.image}
        isLeft
      />
      {downloads.heading && (
        <DownloadWithBackground
          downloadsBackground={
            downloads.downloadsBackground.localFile.childImageSharp
              .gatsbyImageData
          }
          centeredHeading={downloads.headerCentered}
          heading={downloads.heading}
          downloadsText={downloads.text}
          downloadsIcon={downloadsIcon}
          downloadRepeater={downloadRepeater}
        />
      )}
      <WrappedImage
        heading={solution.heading}
        text={solution.text}
        image={solution.image}
      />
      {practiceSingle.bestPracticeSingleTestimonial && (
        <Testimonials
          testimonialRepeater={practiceSingle.bestPracticeSingleTestimonial}
          hasBackground
        />
      )}
      {contactPerson && (
        <ContactPerson
          heading={contactPerson.heading}
          extensionNumber={
            contactPerson.contactPerson.atspcontacts.contactData.extension
          }
          name={contactPerson.contactPerson.title}
          email={contactPerson.contactPerson.atspcontacts.contactData.email}
          portrait={
            contactPerson.contactPerson.atspcontacts.portrait.localFile
              .childImageSharp.gatsbyImageData
          }
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query BestPracticeSingleById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      atspBestPracticeSingle {
        heroLabel
        bestPracticeSingleContact {
          contactPerson {
            ... on WpKontakt {
              title
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          heading
        }
        bestPracticeDownload {
          downloadCard {
            downloadIcon {
              altText
              localFile {
                publicURL
              }
            }
            heading
            downloadRepeater {
              title
              download {
                title
                localFile {
                  publicURL
                }
              }
            }
          }
          heading
          headingCentered
          text
          downloadsBackground {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        bestPracticeSingleApproach {
          heading
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        bestPracticeSingleChallenge {
          heading
          text
          introCard {
            branche
            customer
            projectTitle
            customerLogo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
        bestPracticeSingleSolution {
          heading
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        bestPracticeSingleTestimonial {
          text
          author {
            name
            function
            portrait {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
export default BestPracticeSingle
