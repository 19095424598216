import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Section,
  FlexboxRow,
  FlexboxCol,
  Heading,
  Wysiwyg,
} from 'src/components/Base'
import BaseCard from 'src/components/Cards/BaseCard'
import SocialShare from 'src/components/SocialShare'
import {
  cProjectDetailsLabel,
  cIntroCard,
  cIntroCardRow,
  cIntroCardHeader,
  cIntroCardRight,
  cIntroChallengeText,
  cIntroColumn,
  cIntroCardColumn,
  cIntroCardLogo,
  cSocialShareBox,
} from './styles.module.scss'

const IntroWithCard = ({
  heading,
  introText,
  introCard,
  socialSlug,
  socialTitle,
}) => {
  const data = useStaticQuery(graphql`
    query introCardLabels {
      wp {
        themeGeneralSettings {
          introCardLabels {
            introCard {
              branche
              client
              projectDetails
              projectTitle
            }
          }
        }
      }
    }
  `)

  const introCardClasses = classNames({
    [cIntroColumn]: true,
    [cIntroCardColumn]: true,
  })

  const cardHeader = (
    <div className={cIntroCardHeader}>
      <GatsbyImage
        className={cIntroCardLogo}
        image={getImage(introCard.customerLogo.localFile.childImageSharp)}
        alt={introCard.customerLogo.altText}
      />
      <div className={cProjectDetailsLabel}>
        {data.wp.themeGeneralSettings.introCardLabels.introCard.projectDetails}
      </div>
    </div>
  )
  const cardBody = (
    <>
      <FlexboxRow className={cIntroCardRow}>
        <FlexboxCol width={6}>
          {data.wp.themeGeneralSettings.introCardLabels.introCard.projectTitle}
        </FlexboxCol>
        <FlexboxCol className={cIntroCardRight} width={6}>
          {introCard.projectTitle}
        </FlexboxCol>
      </FlexboxRow>
      <FlexboxRow className={cIntroCardRow}>
        <FlexboxCol width={6}>
          {data.wp.themeGeneralSettings.introCardLabels.introCard.branche}
        </FlexboxCol>
        <FlexboxCol className={cIntroCardRight} width={6}>
          {introCard.branche}
        </FlexboxCol>
      </FlexboxRow>
      <FlexboxRow className={cIntroCardRow}>
        <FlexboxCol width={6}>
          {data.wp.themeGeneralSettings.introCardLabels.introCard.client}
        </FlexboxCol>
        <FlexboxCol className={cIntroCardRight} width={6}>
          {introCard.customer}
        </FlexboxCol>
      </FlexboxRow>
    </>
  )

  return (
    <Section hasBackground>
      <FlexboxRow>
        <FlexboxCol className={cIntroColumn} width={8}>
          <Heading as="h3" size={3} color="dark">
            {heading}
          </Heading>
          <Wysiwyg className={cIntroChallengeText}>{introText}</Wysiwyg>
        </FlexboxCol>
        <FlexboxCol className={introCardClasses} width={4}>
          <BaseCard
            className={cIntroCard}
            headerHasNoPadding
            bodyHasNoPadding
            header={cardHeader}
            body={cardBody}
            hasBorder
          />
          <SocialShare
            className={cSocialShareBox}
            pageSlug={socialSlug}
            pageTitle={socialTitle}
            shareBestPractice
          />
        </FlexboxCol>
      </FlexboxRow>
    </Section>
  )
}

IntroWithCard.propTypes = {
  heading: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  introCard: PropTypes.object.isRequired,
}

export default IntroWithCard
