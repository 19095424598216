import React from 'react'
import PropTypes from 'prop-types'
import {
  cSocialIcon,
  cSocialShareWrapper,
  cSocialShareBestPractice,
} from './styles.module.scss'
import LinkedinIcon from './LinkedIn-icon.png'
import WhatsAppIcon from './WhatsApp-Icon.png'
import EmailIcon from './support-email-icon.svg'
import { Heading, Link } from 'src/components/Base'
import classNames from 'classnames'

const SocialShare = ({ pageSlug, pageTitle, className, shareBestPractice }) => {
  const wrapperClasses = classNames({
    [cSocialShareWrapper]: true,
    [cSocialShareBestPractice]: shareBestPractice,
  })

  return (
    <div className={className}>
      <Heading as="h3" color="dark" size={3}>
        Share:
      </Heading>
      <div className={wrapperClasses}>
        <ul>
          <li>
            <Link
              as="a"
              external
              href={
                `https://www.linkedin.com/sharing/share-offsite?url=https://atsp.com/` +
                pageSlug
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={cSocialIcon} src={LinkedinIcon} />
            </Link>
          </li>
          <li>
            <Link
              as="a"
              external
              href={`whatsapp://send?text=https://atsp.com/` + pageSlug}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={cSocialIcon} src={WhatsAppIcon} />
            </Link>
          </li>
          <li>
            <Link
              as="a"
              external
              href={
                `mailto:?subject=` +
                pageTitle +
                `&body=https://atsp.com/` +
                pageSlug
              }
              title="Share by Email"
            >
              <img className={cSocialIcon} src={EmailIcon} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

SocialShare.propTypes = {
  pageLink: PropTypes.string.isRequired,
}

SocialShare.defaultProps = {
  pageLink: '',
}

export default SocialShare
