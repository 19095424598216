import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Heading, Wysiwyg, Section } from 'src/components/Base'
import { cImageWrapper, cIsLeft } from './styles.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const WrappedImage = ({ heading, text, isLeft, image, hasBackground }) => {
  const wrappedImageClasses = classNames({
    [cImageWrapper]: true,
    [cIsLeft]: isLeft,
  })

  const body = (
    <>
      {image && (
        <div className={wrappedImageClasses}>
          <GatsbyImage
            alt={image.altText}
            image={getImage(image.localFile.childImageSharp)}
          />
        </div>
      )}
      <Heading as="h3" size={3} color="dark">
        {heading}
      </Heading>
      <Wysiwyg>{text}</Wysiwyg>
    </>
  )

  return <Section hasBackground={hasBackground}>{body}</Section>
}

WrappedImage.defaultProps = {
  hasReadMore: false,
  isLeft: false,
}

WrappedImage.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  hasBackground: PropTypes.bool,
  isLeft: PropTypes.bool,
}

export default WrappedImage
